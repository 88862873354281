<template>
  <div
    class="col-xxl-2 col-md-4 col-6 my-1"
    @click="setFromDashboard(statusCountCard.value)"
  >
    <div class="card" :class="statusCountCard.value">
      <!-- <div class="card"> -->
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <img v-if="statusCountCard.value == 'pending'" :src="statusCountCard.icon" width="20px" height="auto" class="icon p-1"/>
          <img v-else :src="statusCountCard.icon" width="24px" height="auto" class="icon p-1"/>
          <div class="card-topic p-1" :class="statusCountCard.value">
            {{ statusCountCard.name }}
          </div>
        </div>
        <div >
          <svg
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.94945 2.12731L10.6607 1.41602H9.65482H7.49982C7.38932 1.41602 7.28334 1.37212 7.2052 1.29398C7.12706 1.21584 7.08316 1.10986 7.08316 0.999349C7.08316 0.888842 7.12706 0.782861 7.2052 0.704721C7.28334 0.626581 7.38932 0.582682 7.49982 0.582682H11.6665C11.777 0.582682 11.883 0.626581 11.9611 0.704721C12.0393 0.782861 12.0832 0.888841 12.0832 0.999349V5.16602C12.0832 5.27652 12.0393 5.3825 11.9611 5.46064C11.883 5.53878 11.777 5.58268 11.6665 5.58268C11.556 5.58268 11.45 5.53878 11.3719 5.46064C11.2937 5.3825 11.2498 5.27652 11.2498 5.16602V3.01102V2.00509L10.5385 2.71639L5.29436 7.96055L5.29432 7.96051L5.28929 7.96572C5.25085 8.00551 5.20487 8.03726 5.15404 8.05909C5.1032 8.08093 5.04853 8.09243 4.9932 8.09291C4.93788 8.09339 4.88301 8.08284 4.83181 8.06189C4.7806 8.04094 4.73408 8.01 4.69496 7.97088C4.65583 7.93176 4.62489 7.88524 4.60394 7.83403C4.58299 7.78283 4.57245 7.72796 4.57293 7.67263C4.57341 7.61731 4.58491 7.56263 4.60674 7.5118C4.62858 7.46097 4.66032 7.41499 4.70012 7.37655L4.70016 7.3766L4.70528 7.37148L9.94945 2.12731Z"
              fill="#7C7C7C"
              stroke="#7C7C7C"
              stroke-width="0.833333"
            />
            <path
              d="M2.49967 1.83203C2.05765 1.83203 1.63372 2.00763 1.32116 2.32019C1.0086 2.63275 0.833008 3.05667 0.833008 3.4987V10.1654C0.833008 10.6074 1.0086 11.0313 1.32116 11.3439C1.63372 11.6564 2.05765 11.832 2.49967 11.832H9.16634C9.60837 11.832 10.0323 11.6564 10.3449 11.3439C10.6574 11.0313 10.833 10.6074 10.833 10.1654V7.66536C10.833 7.44435 10.7452 7.23239 10.5889 7.07611C10.4326 6.91983 10.2207 6.83203 9.99967 6.83203C9.77866 6.83203 9.5667 6.91983 9.41042 7.07611C9.25414 7.23239 9.16634 7.44435 9.16634 7.66536V10.1654H2.49967V3.4987H4.99967C5.22069 3.4987 5.43265 3.4109 5.58893 3.25462C5.74521 3.09834 5.83301 2.88638 5.83301 2.66536C5.83301 2.44435 5.74521 2.23239 5.58893 2.07611C5.43265 1.91983 5.22069 1.83203 4.99967 1.83203H2.49967Z"
              fill="#7C7C7C"
            />
          </svg>
        </div>
      </div>
      <div class="count">{{ statusCountCard.count }}</div>
      <!-- <div class="count">asdfghj</div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';


export default {
  props: {
    statusCountCard: {
      type: Object,
      default: null,
    },
  },
  computed:{
    ...mapGetters({
      maintenanceRole: "maintenance/maintenanceRole",
    })
  },
  methods: {
    setFromDashboard(value) {
      if (value !== "other") {
        this.$store.commit("maintenance/setStatusFromDashboard", value);
        if(this.maintenanceRole == 'high-technician'){
          this.$router.push({ name: "MaintenanceReportAll" });
        } else {
          this.$router.push({ name: "MyMaintenanceReportList" });
        }
      } 
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 10px 15px;
  text-align: center;
  &.pending {
    background-color: #e7e7e7;
  }
  &.in_progress {
    background-color: #fffce6;
  }
  &.complete {
    background-color: #effff2;
  }
  &.cancel {
    background-color: #c6c6c6;
  }
  &.reject {
    background-color: #ffeded;
  }
  &.other {
    background-color: #d9ebff;
  }
}

.card-topic {
  font-size: 20px;
  font-weight: 400;
  &.pending {
    color: #7c7c7c;
  }
  &.in_progress {
    color: #e39600;
  }
  &.complete {
    color: #00ab1b;
  }
  &.cancel {
    color: #000000;
  }
  &.reject {
    color: #ed1616;
  }
  &.other {
    color: #017afd;
  }
}
.count {
  font-size: 60px;
  font-weight: 300;
}
img {
  max-width: 30px;
  height: auto;
  object-fit: contain;
}
@media screen and (max-width: 600px) {
  .card-topic {
    font-size: 15px;
    font-weight: 400;
  }
}
</style>
